<template>
  <div>
    <div class="d-flex flex-wrap align-items-center mb-4">
      <ConsoleLink :to="{ name: 'store.list' }" class="mr-2 mb-2">
        <a-icon type="arrow-left" />
      </ConsoleLink>
      <h4 class="mb-0 mr-5 mb-2">
        {{ $t('sideBar.add_store') }}
      </h4>
      <a-breadcrumb separator=">" class="mb-2">
        <a-breadcrumb-item>
          <ConsoleLink :to="{ name: 'store.list' }">
            {{ $t('store_list_page.store_list') }}
          </ConsoleLink>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          {{ $t('sideBar.add_store') }}
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!-- === Belum ada api nyimpen ke warehouse  === -->
    <!-- <div class="d-flex align-items-center justify-content-between mt-5">
      <div :class="$style.headLeft">
        <h3>
          {{ $t('add_store.channel_integration') }}
        </h3>
        <span>
          <a-icon type="info-circle" class="mr-2" />
          {{ $t('add_store.mandatory_selected_warehouse') }}
        </span>
      </div>
      <div :class="$style.headRight">
        <label class="mr-3 mb-0"><span>*</span> {{ $t('order.warehouse') }}:</label>
        <a-select ref="select" style="width: 120px">
          <a-select-option value="jakarta">
            Jakarta
          </a-select-option>
          <a-select-option value="bogor">
            Bogor
          </a-select-option>
        </a-select>
      </div>
    </div> -->

    <div :class="$style.wrapContent" class="mt-4">
      <!-- === List Marketplace === -->
      <a-card :title="$t('add_store.marketplace_integration')">
        <template v-for="(data, i) in dataMarketPlace">
          <card :key="i" :data-card="data" :open-modal="data?.redirectIntegrate ? openModal : data?.channelCode === 'blibli_id' ? openModalBlibli : openModalWithForm" />
        </template>
      </a-card>

      <!-- === List Fulfilment === -->
      <!-- <a-card :title="$t('add_store.fulfillment_integration')" class="mt-4">
        <template v-for="(data, i) in dataFulfilment">
          <card :key="i" :data-card="data" :open-modal="openModal" />
        </template>
      </a-card> -->

      <modal-sync
        :visible="modalSync"
        :open-modal="openModal"
        :open-modal-success="openModalSuccess"
        :id-data="idData"
      />
      <modal-sync-with-form
        :visible="modalSyncWithForm"
        :open-modal="openModalWithForm"
        :open-modal-success="openModalSuccess"
        :id-data="idData"
      />
      <modal-sync-blibli
        :visible="modalSyncBlibli"
        :open-modal="openModalBlibli"
        :open-modal-success="openModalSuccess"
        :id-data="idData"
      />
      <modal-add-shop 
        :id-data="parseInt(integration?.marketplace.aggregator_id)"
      />
      <modal-success-sync />
      <modal-add-warehouse />

      <a-modal
        :visible="visible"
        :footer="null"
        :mask-closable="false"
        :closable="false"
        :keyboard="false"
        :width="320"
        centered
      >
        <div class="text-center">
          <a-icon type="warning" theme="twoTone" two-tone-color="#ffa500" style="font-size: 72px" />
          <p class="mt-4 mb-0" style="font-weight: bold;">
            Integrasi Tidak Dapat Dilakukan.
          </p>
          <p>Harap untuk menginput Data Gudang Anda terlebih dahulu</p>
          <a-button type="primary" style="font-weight: bold;" @click="toWarehouseForm">
            Input Data Gudang
          </a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'
import Card from '@/components/Store/AddStore/Card.vue'
import ModalSync from '@/components/Store/AddStore/ModalSync.vue'
import ModalSyncWithForm from '@/components/Store/AddStore/ModalSyncWithForm.vue'
import ModalSyncBlibli from '@/components/Store/AddStore/ModalSyncBlibli'
import ModalSuccessSync from '@/components/Store/AddStore/ModalSuccessSync.vue'
import ModalAddWarehouse from '@/components/Store/AddStore/ModalAddWarehouse.vue'
import ModalAddShop from '@/components/Store/AddStore/ModalAddShop.vue'

import data from '@/services/store/data'

export default {
  name: 'AddStore',
  components: {
    ConsoleLink,
    Card,
    ModalSync,
    ModalSuccessSync,
    ModalAddWarehouse,
    ModalAddShop,
    ModalSyncWithForm,
    ModalSyncBlibli,
  },
  data() {
    return {
      visible: false,
      modalSync: false,
      modalSyncWithForm: false,
      modalSyncBlibli: false,
      modalSuccessSync: false,
      modalListMW: false,
      idData: null,
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
    dataMarketPlace() {
      const mpList = data.filter((val) => val.type === 'store')
      return ['TDHgHAQcdyCvXdQ8KnqryTHr3BpXzP', 'pca9a598e66e94c1180669ff945807', 'abd4de7a371f4a6dbd2fe60084d1e1'].includes(this.$route.query?.workspace_id)
        ? mpList
        : mpList.filter(mp => mp.id != 5 && mp.id != 6)
    },
    dataFulfilment() {
      return data.filter((val) => val.type === 'warehouse')
    },
  },
  mounted() {
    this.fetchWarehouseList()
  },
  methods: {
    async fetchWarehouseList() {
      return await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
        })
        .then(response => {
          if(!response.length) {
            this.visible = true
          } else {
            this.syncStore()
          }
        })
        .catch(err => {
          this.visible = true
          console.error(err)
        })
    },
    syncStore() {
      const local = JSON.parse(localStorage.getItem('pathCallback'))
      /* if have code after sync tiktok */
      if (local) {
        const localQuery = {
          ...local,
          query: {
            ...local.query,
            ...this.$route.query,
          },
        }
        localStorage.setItem('pathCallback', JSON.stringify(localQuery))
        if (local?.query?.name?.toLowerCase() === 'shopee') {
          this.$store.dispatch('integration/SYNCSHOPEECONFIRM', {
            sales_channel: local?.query?.sales_channel,
            client_id: local?.query?.client_id,
            business: local?.query?.business_id,
            shop_id: local.query?.shop_id,
            code: local.query?.code,
          }).then(({ data }) => {
            let modalAddWarehouse = true
            if(data?.code != null && !['200', '201'].includes(data?.code)) {
              modalAddWarehouse = false
              this.$notification.error({
                message: 'Integrasi Shopee Gagal',
                description: data?.message,
              })
            }
            
            if (typeof localStorage.getItem('pathCallback') === 'string') {
              localStorage.removeItem('pathCallback')
            }
            this.$router.push({
              path: '/add-store',
              query: { ...this.$route.query, business_id: localQuery?.query?.business_id, channel_id: data?.data?.channel_id, name: 'shopee', code: 'shopee_id' },
            })
            this.$store.commit('integration/SET_MODAL_INTEGRATION_ADD_WAREHOUSE', modalAddWarehouse)
          })
          .catch(err => {
            console.error(err)
            this.$notification.error({
              message: err.response.data.message,
            })
          })
        } else if (local?.query?.name?.toLowerCase() === 'tiktok') {
          if (
            local?.query.code &&
            local?.query['company-aggregator-id'] &&
            local?.query.name
          ) {
            this.$store.dispatch('integration/ADDSTOREMARKETPLACE', {
              name: local.query.name,
              aggregator_id: local.query['company-aggregator-id'],
              code: local.query.code,
              business_id: local?.query?.business_id,
            }).then(({ data }) => {
              let modalAddWarehouse = true
              if(data?.data?.authorized_shops?.[0]?.code != null && !['200', '201'].includes(data?.data?.authorized_shops?.[0]?.code)) {
                modalAddWarehouse = false
                this.$notification.error({
                  message: 'Integrasi Tiktok Gagal',
                  description: data?.data?.authorized_shops?.[0]?.message,
                })
              }

              if (typeof localStorage.getItem('pathCallback') === 'string') {
                localStorage.removeItem('pathCallback')
              }
              this.$router.push({
                path: '/add-store',
                query: { ...this.$route.query, business_id: localQuery?.query?.business_id, channel_id: data?.data?.channel_id, name: 'tiktok', code: 'tiktok' },
              })
              this.$store.commit('integration/SET_MODAL_INTEGRATION_ADD_WAREHOUSE', modalAddWarehouse)
            })
          }
        } else if (local?.query?.name?.toLowerCase() === 'lazada') {
          this.$store.dispatch('integration/SYNCLAZADACONFIRM', {
            business_id: local?.query?.business_id,
            code: local?.query?.code,
            client_id: local?.query?.client_id,
            sales_channel: {
              id: local?.query?.sales_channel?.id,
              code: local?.query?.sales_channel?.code,
            },
          }).then(({ data }) => {
            if (typeof localStorage.getItem('pathCallback') === 'string') {
              localStorage.removeItem('pathCallback')
            }
            this.$router.push({
              path: '/add-store',
              query: { ...this.$route.query, business_id: localQuery?.query?.business_id, channel_id: data?.data?.channel_id, name: 'lazada', code: 'lazada_id' },
            })
            this.$store.commit('integration/SET_MODAL_INTEGRATION_ADD_WAREHOUSE', true)
          })
          .catch(err => {
            console.error(err)
            this.$notification.error({
              message: err.response.data.message,
            })
          })
        }
      }
    },
    openModalWithForm(id) {
      this.modalSyncWithForm = !this.modalSyncWithForm
      this.idData = id
    },
    openModalBlibli(id) {
      this.modalSyncBlibli = !this.modalSyncBlibli
      this.idData = id
    },
    openModal(id) {
      this.modalSync =  !this.modalSync
      this.idData = id
    },
    openModalSuccess() {
      this.modalSync = false
      this.modalSuccessSync = !this.modalSuccessSync
    },
    toWarehouseForm() {
      const { workspace_id, business_id, warehouse_id } = this.$route.query
      this.$router.push({
        path: '/warehouse/add-warehouse',
        query: {
          workspace_id,
          business_id,
          warehouse_id,
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>

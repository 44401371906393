<template>
  <a-modal
    :visible="integration.modalIntegrationAddWarehouse"
    :wrap-class-name="$style.modalSync"
    :closable="false"
    :width="700"
  >
    <template v-if="showSuccessIntegrate">
      <img :src="require(`@/assets/store/add-store/success-add.svg`)" style="width:60px !important; height:60px !important;" />
      <h4 class="mt-4">
        Otorisasi Gudang Berhasil!
      </h4>
      <div class="mt-4 w-100 text-center">
        Berhasil menambahkan gudang utama dan gudang channel.
      </div>
    </template>
    <template v-else>
      <h5 class="text-center w-100 mt-4">
        Warehouse
      </h5>
      <a-form-model
        ref="ruleForm"
        class="w-100"
        :model="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-card
          v-for="(formWarehouse, index) in form.warehouseMaster"
          :key="formWarehouse+index"
          class="w-100 mt-2"
        >
          <div
            v-if="index > 0"
            class="position-absolute top-0 right-0 mt-1 mr-2 close-icon"
            @click="() => onRemoveSelectedWarehouse(index)"
          >
            <CloseIcon
              :color="'black'"
            />
          </div>
          <a-form-model-item
            v-if="$route.query.code === 'tiktok'"
            :label="$t('add_store.select_shop')"
            :rules="[{
              required: true,
              trigger: 'change',
            }]"
          >
            <a-select
              v-model="form.selectedShop[index]"
              :show-arrow="true"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in integration?.listShop?.authorized_shops"
                :key="item.shop_id+index"
                :value="item.shop_id"
                :title="item.shop_id"
              >
                {{ item.region }} - {{ item.shop_name }}
              </a-select-option>
              <template #suffixIcon>
                >
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :label="'Warehouse Utama'"
            :rules="[{
              required: true,
              trigger: 'change',
            }]"
          >
            <a-select
              v-model="form.warehouseMaster[index]"
              :show-arrow="true"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(opt, index) in listWarehouseMaster"
                :key="opt.value+index"
                :value="opt.value"
                :title="opt.label"
              >
                {{ opt.label }}
              </a-select-option>
              <template #suffixIcon>
                >
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :label="'Warehouse Channel'"
            :rules="{
              required: true,
              trigger: 'change',
            }"
            class="mb-0"
          >
            <a-select
              v-model="form.warehouseChannel[index]"
              :show-arrow="true"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(opt, index) in listWarehouseChannel"
                :key="opt.value+index"
                :value="opt.value"
                :title="opt.label"
              >
                {{ opt.label }}
              </a-select-option>
              <template #suffixIcon>
                >
              </template>
              <template slot="notFoundContent">
                <a-empty :image="simpleImage" />
                <div
                  class="d-flex justify-content-center btn"
                  style="padding: 4px 8px; cursor: pointer"
                  @mousedown="e => e.preventDefault()"
                  @click="getWarehouseChannelList($route.query.channel_id)"
                >
                  refresh
                </div>
              </template>
            </a-select>
          </a-form-model-item>
        </a-card>
      </a-form-model>
      <a-button
        v-if="((form?.warehouseMaster[form?.warehouseMaster?.length - 1] && form?.warehouseChannel[form?.warehouseChannel?.length - 1]) && (form?.warehouseChannel?.length < listWarehouseChannel?.length && form?.warehouseMaster?.length < listWarehouseMaster?.length))"
        type="primary"
        class="d-flex align-items-center w-100 justify-content-center mt-3"
        @click.prevent="onAddWarehouse"
      >
        <PlusIcon class="mr-2" :color="'white'" />
        Tambah Gudang Lain
      </a-button>
    </template>
    <template #footer>
      <template v-if="showSuccessIntegrate">
        <a-button
          type="primary"
          @click="onCloseSuccess"
        >
          OK
        </a-button>
      </template>
      <template v-else>
        <a-button
          class="mr-2"
          @click="onClose"
        >
          Kembali
        </a-button>
        <a-button
          type="primary"
          form="form-integrate"
          :disabled="!(form.warehouseMaster[0] !== null && form.warehouseChannel[0] !== null)"
          :loading="loading"
          @click.prevent="loading = true, handleSubmitForm()"
        >
          Submit
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import PlusIcon from '@/components/Icons/PlusIcon.vue'
import CloseIcon from '@/components/Icons/Close.vue'
import { Empty } from 'ant-design-vue';

export default {
  name: 'ModalAddWarehouse',
  components: {
    PlusIcon,
    CloseIcon,
  },
  props: {
  },
  data: function () {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      showSuccessIntegrate: false,
      form: {
        warehouseMaster: [null],
        warehouseChannel: [null],
        selectedShop: [null],
      },
      listWarehouseMaster: [],
      listWarehouseChannel: [],
      loading: false,
      localData: null,
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
  },
  watch: {
    'integration.modalIntegrationAddWarehouse': {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value) {
          const query = this.$route.query
          this.getWarehouseList(query?.business_id)
          this.getWarehouseChannelList(query?.channel_id)
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    async handleSubmitForm() {
      this.loading = true
      const query = this.$route.query
      if (['shopee_id', 'tiktok' ].includes(query?.code)) {
        const promises = []
        const delayIncrement = 1000
        let delay = 0
        this.form.warehouseMaster.map((obj, index) => {
          if(obj && this.form.warehouseChannel[index] !== null) {
            promises.push(
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async function(resolve) {
                await new Promise(res => setTimeout(res, delay))
                resolve()
              })
              .then(async () => {
                return await this.$store.dispatch('integration/UPDATEWAREHOUSECHANNEL', {
                  channel_code: query?.code,
                  business_id: query?.business_id,
                  payload: {
                    channel: {
                      id: query?.channel_id,
                      code: query?.code,
                      name: query?.name,
                    },
                    warehouse_id: obj,
                    warehouse_channel: {
                      id: this.form.warehouseChannel[index],
                      name: this.listWarehouseChannel.find((obj2) => obj2.value ===  this.form.warehouseChannel[index])?.label ?? '',
                    },
                  },
                })
              }),
            )
            delay += delayIncrement
          }
        })
        Promise.all(promises)
          .then(() => {
            this.loading = false
            this.showSuccessIntegrate = true
          })
          .catch(err => {
            this.loading = false
            console.error(err)
            this.$notification.error({
              message: err.response?.data?.message,
            })
            this.correctionConnectedWarehouse()
          })
      } else if (['lazada_id'].includes(query?.code)) {
        const warehouses = this.form.warehouseMaster.map((obj, index) => {
          if(obj && this.form.warehouseChannel[index] !== null) {
            return {
              warehouse_id: obj,
              channel_warehouse_id: this.form.warehouseChannel[index],
              channel_warehouse_name: this.listWarehouseChannel.find((obj2) => obj2.value ===  this.form.warehouseChannel[index])?.label ?? '',
            }
          }
        })
        await this.$store.dispatch('integration/UPDATEWAREHOUSELAZADA', {
          channel_id: query?.channel_id,
          payload: { warehouses },
        })
          .then(() => {
            this.loading = false
            this.showSuccessIntegrate = true
          })
          .catch(err => {
            this.loading = false
            console.error(err)
            this.$notification.error({
              message: err.response?.data?.message,
            })
            this.correctionConnectedWarehouse()
          })
      } else {
        this.loading = false
      }
    },
    correctionConnectedWarehouse() {
      const query = this.$route.query
      this.$store.dispatch('warehouse/GETCONNECTEDWAREHOUSECHANNELLIST', {
        channel_id: query?.channel_id,
        sales_channel: query?.code,
        business_id: query?.business_id,
      })
      .then(data => {
        let newFormWarehouseMaster = []
        let newFormWarehouseChannel = []
        this.form?.warehouseMaster?.map((whsMasterId, index) => {
          let currentWhsMasterId = whsMasterId
          let currentWhsChannelId = this.form?.warehouseChannel[index]
          let findSameWhs = data.findIndex((obj) => obj.warehouse_channel_id === currentWhsChannelId && obj.warehouse_master_id === currentWhsMasterId)
          if(findSameWhs === -1) {
            newFormWarehouseMaster.push(currentWhsMasterId)
            newFormWarehouseChannel.push(currentWhsChannelId)
          }
        })
        this.form.warehouseMaster = newFormWarehouseMaster
        this.form.warehouseChannel = newFormWarehouseChannel
      })
      .catch(err => {
        console.error(err)
        return err
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    resetForm() {
      this.listWarehouseMaster = []
      this.listWarehouseChannel = []
      this.form = {
        warehouseMaster: [null],
        warehouseChannel: [null],
      }
    },
    getWarehouseList(business_id) {
      this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          business_id: business_id,
          channel_code: this.$route.query.code,
        })
        .then(response => {
          this.listWarehouseMaster = response
          if (response?.length > 0) {
            this.form.warehouseMaster[0] = response[0].value
          }
          if (this.integration?.listShop?.authorized_shops?.length > 0) {
            this.form.selectedShop[0] = this.integration?.listShop?.authorized_shops[0].shop_id
          }
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    getWarehouseChannelList(channel_id) {
      const query = this.$route.query
      if (query?.code === 'shopee_id') {
        this.$store
          .dispatch('warehouse/GETSHOPEEWAREHOUSECHANNELLIST', {
            channel_id: channel_id,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      } else if (query?.code === 'tiktok') {
        this.$store
          .dispatch('warehouse/GETWAREHOUSECHANNELLIST', {
            channel_id: channel_id,
            sales_channel: query?.code,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      } else if (query?.code === 'lazada_id') {
        this.$store
          .dispatch('warehouse/GETLAZADAWAREHOUSECHANNELLIST', {
            channel_id: channel_id,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      }
    },
    onAddWarehouse() {
      this.form.warehouseMaster.push(null)
      this.form.warehouseChannel.push(null)
    },
    onRemoveSelectedWarehouse(idx) {
      this.form.warehouseMaster.splice(idx, 1)
      this.form.warehouseChannel.splice(idx, 1)
      document.activeElement.blur()
    },
    onClose() {
      this.$store.commit('integration/SET_MODAL_INTEGRATION_ADD_WAREHOUSE', false)
    },
    onCloseSuccess() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>